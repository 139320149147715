import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  AddCircle,
  CancelRounded,
  ChevronRight,
  ExpandMore,
} from "@material-ui/icons";
import { default as PrintIcon } from "../../../Print.svg";
import React, { Component, Fragment } from "react";
import { ReactComponent as FrecciaSu } from "../../../frecciaSu.svg";
import { ReactComponent as FrecciaGiu } from "../../../frecciaGiu.svg";
import ParagrafoParti from "./ParagrafoParti";
import theme from "../../../theme";

class CapitoloParti extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps.item2) !== JSON.stringify(this.props.item2)) {
      return true;
    }
    if (
      nextProps.activateMovement !== this.props.activateMovement &&
      this.props.item.isTabOpen
    ) {
      return true;
    }
    if (this.props.activateMovement === false && !this.props.item.isTabOpen) {
      if (
        nextProps.base !== this.props.base ||
        nextProps.sv !== this.props.sv ||
        nextProps.ul !== this.props.ul ||
        nextProps.area1 !== this.props.area1 ||
        nextProps.area2 !== this.props.area2
      ) {
        return false;
      }
      if (nextProps.item.titolo !== this.props.item.titolo) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  handleParagrafoOpening = () => {
    if (this.props.item2.paragrafi.length !== 0) {
      this.props.handleCapitoloOpen(this.props.index, this.props.index2);
    }
  };

  render() {
    return (
      <Fragment key={this.props.index2}>
        <div style={{ marginBottom: 50 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ border: 0, width: 200 }} align="center">
                    <IconButton
                      size="medium"
                      onClick={() => {
                        this.handleParagrafoOpening();
                      }}
                    >
                      {this.props.item2.isTabOpen &&
                      this.props.item2.paragrafi.length > 0 ? (
                        <ExpandMore htmlColor="#f7a616" />
                      ) : (
                        <ChevronRight htmlColor={this.props.item2.paragrafi.length === 0 ? "#e0e0e0" : "#f7a616"} />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderTop: "2px solid lightgray",
                      borderBottom: "2px solid lightgray",
                      borderLeft: "2px solid lightgray",
                      borderRight: "2px solid lightgray",
                      width: 150,
                    }}
                    align="center"
                  >
                    Capitolo
                  </TableCell>
                  <TableCell
                    style={
                      this.props.isModello
                        ? {
                            border: "2px solid lightgray",
                            borderTop: "2px solid lightgray",
                            borderBottom: "2px solid lightgray",
                            borderLeft: 0,
                            width: 1150,
                          }
                        : {
                            border: "2px solid lightgray",
                            borderTop: "2px solid lightgray",
                            borderBottom: "2px solid lightgray",
                            borderLeft: 0,
                            width: 1150,
                          }
                    }
                    align="center"
                  >
                    Titolo
                  </TableCell>
                  <TableCell style={{ border: 0 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={this.props.item2.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ border: 0 }} align="center">
                    {!this.props.isModello ? (
                      <Fragment>
                        <div
                          style={{ flexDirection: "column", display: "flex" }}
                        >
                          <img
                            style={{
                              width: 21,
                              height: 21,
                              alignSelf: "center",
                            }}
                            src={PrintIcon}
                            alt=""
                          />
                          {/*<PrintIcon fill="black" width={23} height={23} style={{ alignSelf: "center" }}></PrintIcon>*/}
                        </div>
                        <Checkbox
                          checked={this.props.item2.isSelectedForPDF}
                          onClick={() => {
                            this.props.handleAggiuntaAlManualeCapitolo(
                              this.props.index,
                              this.props.index2
                            );
                          }}
                          value={this.props.item2.isSelectedForPDF}
                          disabled={this.props.activateMovement}
                          style={{
                            color: this.props.activateMovement
                              ? theme.palette.disabled.main
                              : theme.palette.cndColors.yellow,
                            "&.MaterialUiChecked": {
                              color: theme.palette.cndColors.yellow,
                            },
                          }}
                        />
                      </Fragment>
                    ) : null}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderTop: "2px solid lightgray",
                      borderBottom: "2px solid lightgray",
                      borderLeft: "2px solid lightgray",
                      borderRight: "2px solid lightgray",
                    }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    {
                      <TextField
                        multiline
                        disabled={this.props.activateMovement}
                        onInput={(e) => {
                          this.setState({
                            indiceCapitolo: e.target.value,
                          });
                          this.props.handleCambioIndiceCapitolo(
                            e.target.value,
                            this.props.index,
                            this.props.index2
                          );
                          /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                        }}
                        onBlur={() => {
                          this.props.saveCapitoloData(
                            this.props.item,
                            this.props.item2,
                            this.props.index,
                            this.props.index2
                          );
                        }}
                        inputProps={{
                          style: { textAlign: "center", color: "black" },
                        }}
                        InputProps={{ disableUnderline: true }}
                        value={
                          this.props.item2.numeroIndice !== null
                            ? this.props.item2.numeroIndice
                            : ""
                        }
                      />
                    }
                  </TableCell>
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderLeft: 0,
                      width: 200,
                    }}
                    align="center"
                  >
                    {
                      <TextField
                        multiline
                        disabled={this.props.activateMovement}
                        onInput={(e) => {
                          this.setState({
                            titoloCapitolo: e.target.value,
                          });
                          this.props.handleCambioTitoloCapitolo(
                            e,
                            this.props.index,
                            this.props.index2
                          );
                          /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                        }}
                        onBlur={() => {
                          this.props.saveCapitoloData(
                            this.props.item,
                            this.props.item2,
                            this.props.index,
                            this.props.index2
                          );
                        }}
                        inputProps={{
                          style: { textAlign: "center", color: "black" },
                        }}
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        value={this.props.item2.titolo}
                      ></TextField>
                    }
                  </TableCell>
                  <TableCell style={{ border: 0 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <IconButton
                        size="medium"
                        disabled={this.props.activateMovement}
                        onClick={() => {
                          this.props.handleAddParagrafo(
                            this.props.item2,
                            this.props.index,
                            this.props.index2
                          );
                        }}
                      >
                        <AddCircle
                          htmlColor={
                            this.props.activateMovement ? "#e0e0e0" : "#f7a616"
                          }
                        />
                      </IconButton>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 3,
                        }}
                      >
                        <IconButton
                          disabled={!this.props.activateMovement}
                          size="small"
                          onClick={() => {
                            this.props.handleCapitoloMovementUpward(
                              this.props.index,
                              this.props.index2
                            );
                          }}
                        >
                          <FrecciaSu
                            width={15}
                            height={15}
                            fill={
                              this.props.activateMovement
                                ? "#f7a616"
                                : "#e0e0e0"
                            }
                          />
                        </IconButton>
                        <IconButton
                          disabled={!this.props.activateMovement}
                          size="small"
                          onClick={() => {
                            this.props.handleCapitoloMovementDownward(
                              this.props.index,
                              this.props.index2
                            );
                          }}
                        >
                          <FrecciaGiu
                            width={15}
                            height={15}
                            fill={
                              this.props.activateMovement
                                ? "#f7a616"
                                : "#e0e0e0"
                            }
                          />
                        </IconButton>
                      </div>
                      <IconButton
                        size="medium"
                        disabled={this.props.activateMovement}
                        onClick={() => {
                          this.props.handleDeleteCapitolo(
                            this.props.index,
                            this.props.index2
                          );
                        }}
                      >
                        <CancelRounded
                          htmlColor={
                            this.props.activateMovement ? "#e0e0e0" : "#ff4600"
                          }
                        />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={
                      this.props.item2.isTabOpen &&
                      this.props.item2.paragrafi.length > 0
                        ? {
                            paddingBottom: 10,
                            paddingTop: 0,
                            borderBottom: 0,
                            borderTop: 0,
                          }
                        : {
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderBottom: 0,
                            borderTop: 0,
                          }
                    }
                    colSpan={12}
                  >
                    <Collapse
                      unmountOnExit={true}
                      style={{
                        marginLeft: "12%",
                      }}
                      in={this.props.item2.isTabOpen}
                    >
                      <Box>
                        <Table
                          style={
                            {
                              //borderCollapse: 'separate',
                              //borderSpacing: '0px 20px'
                            }
                          }
                          aria-label="paragrafi"
                        >
                          {this.props.item2.paragrafi.map((item3, index3) => {
                            return (
                              <ParagrafoParti
                                isModello={this.props.isModello}
                                key={index3}
                                item3={item3}
                                item2={this.props.item2}
                                index3={index3}
                                index={this.props.index}
                                index2={this.props.index2}
                                titoloCapitolo={this.props.item2.titoloCapitolo}
                                activateMovement={this.props.activateMovement}
                                handleParagrafoDelete={
                                  this.props.handleParagrafoDelete
                                }
                                handleParagrafoMovementUpward={
                                  this.props.handleParagrafoMovementUpward
                                }
                                handleParagrafoMovementDownward={
                                  this.props.handleParagrafoMovementDownward
                                }
                                handleSottoparagrafoDelete={
                                  this.props.handleSottoparagrafoDelete
                                }
                                handleSottoparagrafoMovementDownward={
                                  this.props
                                    .handleSottoparagrafoMovementDownward
                                }
                                handleSottoparagrafoMovementUpward={
                                  this.props.handleSottoparagrafoMovementUpward
                                }
                                handleAddSottoparagrafo={
                                  this.props.handleAddSottoparagrafo
                                }
                                handleParagrafoRedirect={
                                  this.props.handleParagrafoRedirect
                                }
                                handleAggiuntaAlManualeParagrafo={
                                  this.props.handleAggiuntaAlManualeParagrafo
                                }
                                handleAggiuntaAlManualeSottoparagrafo={
                                  this.props
                                    .handleAggiuntaAlManualeSottoparagrafo
                                }
                                handleCambioBaseParagrafo={
                                  this.props.handleCambioBaseParagrafo
                                }
                                handleCambioBaseSottoparagrafo={
                                  this.props.handleCambioBaseSottoparagrafo
                                }
                                handleCambioULParagrafo={
                                  this.props.handleCambioULParagrafo
                                }
                                handleCambioULSottoparagrafo={
                                  this.props.handleCambioULSottoparagrafo
                                }
                                handleCambioSVParagrafo={
                                  this.props.handleCambioSVParagrafo
                                }
                                handleCambioSVSottoparagrafo={
                                  this.props.handleCambioSVSottoparagrafo
                                }
                                handleCambioArea1Paragrafo={
                                  this.props.handleCambioArea1Paragrafo
                                }
                                handleCambioArea1Sottoparagrafo={
                                  this.props.handleCambioArea1Sottoparagrafo
                                }
                                handleCambioArea2Paragrafo={
                                  this.props.handleCambioArea2Paragrafo
                                }
                                handleCambioArea2Sottoparagrafo={
                                  this.props.handleCambioArea2Sottoparagrafo
                                }
                                handleCambioDaEditareParagrafo={
                                  this.props.handleCambioDaEditareParagrafo
                                }
                                handleCambioDaEditareSottoparagrafo={
                                  this.props.handleCambioDaEditareSottoparagrafo
                                }
                                handleCambioIndiceParagrafo={
                                  this.props.handleCambioIndiceParagrafo
                                }
                                handleCambioIndiceSottoparagrafo={
                                  this.props.handleCambioIndiceSottoparagrafo
                                }
                                handleCambioDataParagrafo={
                                  this.props.handleCambioDataParagrafo
                                }
                                handleCambioDataSottoparagrafo={
                                  this.props.handleCambioDataSottoparagrafo
                                }
                                handleCambioTitoloParagrafo={
                                  this.props.handleCambioTitoloParagrafo
                                }
                                handleCambioTitoloSottoparagrafo={
                                  this.props.handleCambioTitoloSottoparagrafo
                                }
                                handleParagrafoOpen={
                                  this.props.handleParagrafoOpen
                                }
                                saveParagrafoData={this.props.saveParagrafoData}
                                saveSottoparagrafoData={
                                  this.props.saveSottoparagrafoData
                                }
                              />
                            );
                          })}
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Fragment>
    );
  }
}
export default CapitoloParti;
