import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  AddCircle,
  CancelRounded,
  ChevronRight,
  Edit,
  ExpandMore,
} from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ReactComponent as CalendarLogo } from "../../../Calendario.svg";
import itLocale from "date-fns/locale/it";
import React, { Component, Fragment } from "react";
import { default as PrintIcon } from "../../../Print.svg";
import SottoparagrafoParti from "./SottoparagrafoParti";
import { ReactComponent as FrecciaSu } from "../../../frecciaSu.svg";
import { ReactComponent as FrecciaGiu } from "../../../frecciaGiu.svg";
import theme from "../../../theme";

class ParagrafoParti extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleParagrafoOpening = (index1, index2, index3) => {
    this.props.handleParagrafoOpen(index1, index2, index3);
  };

  handleParagrafoClosing = (index1, index2, index3) => {
    this.props.handleParagrafoOpen(index1, index2, index3);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps.item3) !== JSON.stringify(this.props.item3)) {
      return true;
    }
    if (JSON.stringify(nextProps.item2) !== JSON.stringify(this.props.item2)) {
      return true;
    }
    if (
      nextProps.activateMovement !== this.props.activateMovement &&
      this.props.item2.isTabOpen
    ) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <Fragment key={this.props.index3}>
        <div style={{ marginTop: 50 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ border: 0, width: 110 }}>
                    <IconButton
                      size="medium"
                      onClick={() => {
                        if (this.props.item3.sottoparagrafi.length > 1) {
                          this.handleParagrafoOpening(
                            this.props.index,
                            this.props.index2,
                            this.props.index3
                          );
                        }
                      }}
                    >
                      {this.props.item3.isTabOpen &&
                      this.props.item3.sottoparagrafi.length > 1 ? (
                        <ExpandMore htmlColor="#f7a616" />
                      ) : (
                        <ChevronRight htmlColor={this.props.item3.sottoparagrafi.length === 1 ? "#e0e0e0" : "#f7a616"} />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderLeft: "2px solid lightgray",
                      borderRight: "2px solid lightgray",
                      width: 150,
                    }}
                    align="center"
                  >
                    Paragrafo
                  </TableCell>
                  <TableCell
                    style={
                      !this.props.isModello
                        ? {
                            border: "2px solid lightgray",
                            borderLeft: 0,
                            width: 300,
                          }
                        : {
                            border: "2px solid lightgray",
                            borderLeft: 0,
                            width: 400,
                          }
                    }
                    align="center"
                  >
                    Titolo
                  </TableCell>
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderLeft: 0,
                      width: 100,
                    }}
                    align="center"
                  >
                    Base&nbsp;
                  </TableCell>
                  {!this.props.isModello ? (
                    <TableCell
                      style={{
                        border: "2px solid lightgray",
                        borderLeft: 0,
                        width: 100,
                      }}
                      align="center"
                    >
                      SV
                    </TableCell>
                  ) : null}
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderLeft: 0,
                      width: 100,
                    }}
                    align="center"
                  >
                    UL
                  </TableCell>
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderLeft: 0,
                      width: 100,
                    }}
                    align="center"
                  >
                    Area 1
                  </TableCell>
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderLeft: 0,
                      width: 100,
                    }}
                    align="center"
                  >
                    Area 2
                  </TableCell>
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderLeft: 0,
                      width: 100,
                    }}
                    align="center"
                  >
                    Da personalizzare&nbsp;
                  </TableCell>
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderLeft: 0,
                      minWidth: 175,
                    }}
                    align="center"
                  >
                    Data&nbsp;
                  </TableCell>
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderLeft: 0,
                      width: 100,
                    }}
                    align="center"
                  >
                    Modifica Contenuto
                  </TableCell>
                  <TableCell style={{ border: 0 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ border: "0" }} align="center">
                    {!this.props.isModello ? (
                      <Fragment>
                        <div
                          style={{ flexDirection: "column", display: "flex" }}
                        >
                          <img
                            style={{
                              width: 23,
                              height: 23,
                              alignSelf: "center",
                            }}
                            src={PrintIcon}
                            alt=""
                          />
                        </div>
                        <Checkbox
                          checked={
                            this.props.item3.isSelectedForPDF &&
                            this.props.item2.isSelectedForPDF
                          }
                          onClick={() => {
                            this.props.handleAggiuntaAlManualeParagrafo(
                              this.props.index,
                              this.props.index2,
                              this.props.index3
                            );
                          }}
                          value={
                            this.props.item3.isSelectedForPDF &&
                            this.props.item2.isSelectedForPDF
                          }
                          disabled={
                            !this.props.item2.isSelectedForPDF ||
                            this.props.activateMovement
                          }
                          style={{
                            color:
                              !this.props.item2.isSelectedForPDF ||
                              this.props.activateMovement
                                ? theme.palette.disabled.main
                                : theme.palette.cndColors.yellow,
                            "&.MaterialUiChecked": {
                              color: theme.palette.cndColors.yellow,
                            },
                          }}
                        />
                      </Fragment>
                    ) : null}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "2px solid lightgray",
                      borderLeft: "2px solid lightgray",
                      borderRight: "2px solid lightgray",
                    }}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    {
                      <TextField
                        multiline
                        disabled={this.props.activateMovement}
                        onInput={(e) => {
                          this.setState({
                            indiceParagrafo: e.target.value,
                          });
                          this.props.handleCambioIndiceParagrafo(
                            e.target.value,
                            this.props.index,
                            this.props.index2,
                            this.props.index3
                          );
                          /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                        }}
                        onBlur={() => {
                          this.props.saveParagrafoData(this.props.item3);
                        }}
                        inputProps={{
                          style: { textAlign: "center", color: "black" },
                        }}
                        InputProps={{ disableUnderline: true }}
                        value={
                          this.props.item3.numeroIndice !== null
                            ? this.props.item3.numeroIndice
                            : ""
                        }
                      />
                    }
                  </TableCell>
                  <TableCell
                    style={{ border: "2px solid lightgray", borderLeft: 0 }}
                    align="center"
                  >
                    {
                      <TextField
                        multiline
                        disabled={this.props.activateMovement}
                        onInput={(e) => {
                          this.setState({
                            titoloParagrafo: e.target.value,
                          });
                          this.props.handleCambioTitoloParagrafo(
                            e,
                            this.props.index,
                            this.props.index2,
                            this.props.index3
                          );
                          /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                        }}
                        onBlur={() => {
                          this.props.saveParagrafoData(this.props.item3);
                        }}
                        fullWidth
                        inputProps={{
                          style: { textAlign: "center", color: "black" },
                        }}
                        InputProps={{ disableUnderline: true }}
                        value={this.props.item3.titolo}
                      />
                    }
                  </TableCell>
                  <TableCell
                    style={
                      /*!this.props.isModello ? {border:"2px solid lightgray",borderLeft:0, backgroundColor:"#eeeeee"} : */ {
                        border: "2px solid lightgray",
                        borderLeft: 0,
                      }
                    }
                    align="center"
                  >
                    {
                      /*!this.props.isModello ? this.props.item3.base : */
                      <TextField
                        multiline
                        disabled={this.props.activateMovement}
                        onInput={(e) => {
                          this.setState({
                            baseParagrafo: e.target.value,
                          });
                          this.props.handleCambioBaseParagrafo(
                            e,
                            this.props.index,
                            this.props.index2,
                            this.props.index3
                          );
                          /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                        }}
                        onBlur={() => {
                          this.props.item3.sottoparagrafi.map((item) => {
                            if (item.base === null) {
                              item.base = this.props.item3.base;
                            }
                          });
                          this.props.saveParagrafoData(this.props.item3);
                        }}
                        inputProps={{
                          style: { textAlign: "center", color: "black" },
                        }}
                        InputProps={{ disableUnderline: true }}
                        value={
                          this.props.item3.base !== null
                            ? this.props.item3.base
                            : ""
                        }
                      />
                    }
                  </TableCell>
                  {!this.props.isModello ? (
                    <TableCell
                      style={{ border: "2px solid lightgray", borderLeft: 0 }}
                      align="center"
                    >
                      {
                        <TextField
                          multiline
                          disabled={this.props.activateMovement}
                          onInput={(e) => {
                            this.setState({
                              svParagrafo: e.target.value,
                            });
                            this.props.handleCambioSVParagrafo(
                              e,
                              this.props.index,
                              this.props.index2,
                              this.props.index3
                            );
                            /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                          }}
                          onBlur={() => {
                            this.props.item3.sottoparagrafi.map((item) => {
                              if (item.sv === null) {
                                item.sv = this.props.item3.sv;
                              }
                            });
                            this.props.saveParagrafoData(this.props.item3);
                          }}
                          inputProps={{
                            style: { textAlign: "center", color: "black" },
                          }}
                          InputProps={{ disableUnderline: true }}
                          value={
                            this.props.item3.sv !== null
                              ? this.props.item3.sv
                              : ""
                          }
                        />
                      }
                    </TableCell>
                  ) : null}
                  <TableCell
                    style={{ border: "2px solid lightgray", borderLeft: 0 }}
                    align="center"
                  >
                    {
                      <TextField
                        multiline
                        disabled={this.props.activateMovement}
                        onInput={(e) => {
                          this.setState({
                            ulParagrafo: e.target.value,
                          });
                          this.props.handleCambioULParagrafo(
                            e,
                            this.props.index,
                            this.props.index2,
                            this.props.index3
                          );
                          /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                        }}
                        onBlur={() => {
                          this.props.item3.sottoparagrafi.map((item) => {
                            if (item.ul === null) {
                              item.ul = this.props.item3.ul;
                            }
                          });
                          this.props.saveParagrafoData(this.props.item3);
                        }}
                        inputProps={{
                          style: { textAlign: "center", color: "black" },
                        }}
                        InputProps={{ disableUnderline: true }}
                        value={
                          this.props.item3.ul !== null
                            ? this.props.item3.ul
                            : ""
                        }
                      />
                    }
                  </TableCell>
                  <TableCell
                    style={{ border: "2px solid lightgray", borderLeft: 0 }}
                    align="center"
                  >
                    {
                      <TextField
                        multiline
                        disabled={this.props.activateMovement}
                        onInput={(e) => {
                          this.setState({
                            area1Paragrafo: e.target.value,
                          });
                          this.props.handleCambioArea1Paragrafo(
                            e,
                            this.props.index,
                            this.props.index2,
                            this.props.index3
                          );
                          /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                        }}
                        onBlur={() => {
                          this.props.item3.sottoparagrafi.map((item) => {
                            if (item.area1 === null) {
                              item.area1 = this.props.item3.area1;
                            }
                          });
                          this.props.saveParagrafoData(this.props.item3);
                        }}
                        inputProps={{
                          style: { textAlign: "center", color: "black" },
                        }}
                        InputProps={{ disableUnderline: true }}
                        value={
                          this.props.item3.area1 !== null
                            ? this.props.item3.area1
                            : ""
                        }
                      />
                    }
                  </TableCell>
                  <TableCell
                    style={{ border: "2px solid lightgray", borderLeft: 0 }}
                    align="center"
                  >
                    {
                      <TextField
                        multiline
                        disabled={this.props.activateMovement}
                        onInput={(e) => {
                          this.setState({
                            area2Paragrafo: e.target.value,
                          });
                          this.props.handleCambioArea2Paragrafo(
                            e,
                            this.props.index,
                            this.props.index2,
                            this.props.index3
                          );
                          /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
                        }}
                        onBlur={() => {
                          this.props.item3.sottoparagrafi.map((item) => {
                            if (item.area2 === null) {
                              item.area2 = this.props.item3.area2;
                            }
                          });
                          this.props.saveParagrafoData(this.props.item3);
                        }}
                        inputProps={{
                          style: { textAlign: "center", color: "black" },
                        }}
                        InputProps={{ disableUnderline: true }}
                        value={
                          this.props.item3.area2 !== null
                            ? this.props.item3.area2
                            : ""
                        }
                      />
                    }
                  </TableCell>
                  <TableCell
                    style={
                      this.props.item3.daEditare
                        ? this.props.item3.timeStamp === null ||
                          this.props.isModello
                          ? {
                              border: "2px solid lightgray",
                              borderLeft: 0,
                              backgroundColor: "#fde99f",
                            }
                          : { border: "2px solid lightgray", borderLeft: 0 }
                        : { border: "2px solid lightgray", borderLeft: 0 }
                    }
                    align="center"
                  >
                    {this.props.isModello &&
                    !(
                      localStorage.getItem("superconsulente") !== "true" &&
                      localStorage.getItem("consulenteId") !== "null" &&
                      localStorage.getItem("consulenteId") !== null
                    ) ? (
                      <Select
                        disabled={this.props.activateMovement}
                        defaultValue={false}
                        variant="standard"
                        value={
                          this.props.item3.daEditare !== undefined
                            ? this.props.item3.daEditare
                            : false
                        }
                      >
                        <MenuItem
                          value={true}
                          onClick={() => {
                            this.props.handleCambioDaEditareParagrafo(
                              true,
                              this.props.index,
                              this.props.index2,
                              this.props.index3
                            );
                          }}
                        >
                          Si
                        </MenuItem>
                        <MenuItem
                          value={false}
                          onClick={() => {
                            this.props.handleCambioDaEditareParagrafo(
                              false,
                              this.props.index,
                              this.props.index2,
                              this.props.index3
                            );
                          }}
                        >
                          No
                        </MenuItem>
                      </Select>
                    ) : this.props.item3.daEditare ? (
                      "Si"
                    ) : (
                      "No"
                    )}
                  </TableCell>
                  <TableCell
                    style={{ border: "2px solid lightgray", borderLeft: 0 }}
                    align="center"
                  >
                    {
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={itLocale}
                      >
                        <DatePicker
                          format="dd/MM/yyyy"
                          value={this.props.item3.dataEditing}
                          onChange={(date) =>
                            this.props.handleCambioDataParagrafo(
                              date,
                              this.props.index,
                              this.props.index2,
                              this.props.index3
                            )
                          }
                          disabled={this.props.activateMovement}
                          TextFieldComponent={({ InputProps, ...params }) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...InputProps,
                                disableUnderline: true,
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                      <CalendarLogo
                                        width={21}
                                        height={21}
                                        fill={
                                          this.props.activateMovement
                                            ? theme.palette.disabled.main
                                            : theme.palette.cndColors.yellow
                                        }
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    }
                  </TableCell>
                  <TableCell
                    style={{ border: "2px solid lightgray", borderLeft: 0 }}
                    align="center"
                  >
                    <IconButton
                      disabled={this.props.activateMovement}
                      onClick={() => {
                        this.props.handleParagrafoRedirect(
                          this.props.item3,
                          null
                        );
                      }}
                    >
                      <Edit
                        htmlColor={
                          this.props.activateMovement ? "#e0e0e0" : "#f7a616"
                        }
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell style={{ border: 0 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <IconButton
                        size="medium"
                        disabled={this.props.activateMovement}
                        onClick={() => {
                          this.props.handleAddSottoparagrafo(
                            this.props.index,
                            this.props.index2,
                            this.props.index3
                          );
                        }}
                      >
                        <AddCircle
                          htmlColor={
                            this.props.activateMovement ? "#e0e0e0" : "#f7a616"
                          }
                        />
                      </IconButton>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 3,
                        }}
                      >
                        <IconButton
                          size="small"
                          disabled={!this.props.activateMovement}
                          onClick={() => {
                            this.props.handleParagrafoMovementUpward(
                              this.props.index,
                              this.props.index2,
                              this.props.index3
                            );
                          }}
                        >
                          <FrecciaSu
                            width={15}
                            height={15}
                            fill={
                              this.props.activateMovement
                                ? "#f7a616"
                                : "#e0e0e0"
                            }
                          />
                        </IconButton>
                        <IconButton
                          disabled={!this.props.activateMovement}
                          onClick={() => {
                            this.props.handleParagrafoMovementDownward(
                              this.props.index,
                              this.props.index2,
                              this.props.index3
                            );
                          }}
                          size="small"
                        >
                          <FrecciaGiu
                            width={15}
                            height={15}
                            fill={
                              this.props.activateMovement
                                ? "#f7a616"
                                : "#e0e0e0"
                            }
                          />
                        </IconButton>
                      </div>
                      <IconButton
                        disabled={this.props.activateMovement}
                        onClick={() => {
                          this.props.handleParagrafoDelete(
                            this.props.index,
                            this.props.index2,
                            this.props.index3
                          );
                        }}
                        size="medium"
                      >
                        <CancelRounded
                          htmlColor={
                            this.props.activateMovement ? "#e0e0e0" : "#ff4600"
                          }
                        />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={
                      this.props.item3.isTabOpen &&
                      this.props.item3.sottoparagrafi.length > 0
                        ? {
                            paddingBottom: 10,
                            paddingTop: 50,
                            borderBottom: 0,
                            borderTop: 0,
                          }
                        : {
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderBottom: 0,
                            borderTop: 0,
                          }
                    }
                    colSpan={12}
                  >
                    <Collapse
                      unmountOnExit={true}
                      style={{
                        marginLeft: this.props.isModello ? "13.3%" : "10.5%",
                      }}
                      in={this.props.item3.isTabOpen}
                    >
                      {this.props.item3.sottoparagrafi.length > 0 ? (
                        <Box sx={{ margin: 1 }}>
                          <Table style={{}} aria-label="sottoparagrafi">
                            {this.props.item3.sottoparagrafi.length > 1 ? (
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{ border: 0 }}
                                    align="center"
                                  ></TableCell>
                                  <TableCell
                                    style={{
                                      border: "2px solid lightgray",
                                      borderRight: 0,
                                      width: "10%",
                                    }}
                                    align="center"
                                  >
                                    Sottoparagrafo
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "2px solid lightgray",
                                      borderRight: 0,
                                      minWidth: "35%",
                                    }}
                                    align="center"
                                  >
                                    Titolo
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "2px solid lightgray",
                                      borderRight: 0,
                                      minWidth: 75,
                                    }}
                                    align="center"
                                  >
                                    Base&nbsp;
                                  </TableCell>
                                  {!this.props.isModello ? (
                                    <TableCell
                                      style={{
                                        border: "2px solid lightgray",
                                        borderRight: 0,
                                        minWidth: 75,
                                      }}
                                      align="center"
                                    >
                                      SV&nbsp;
                                    </TableCell>
                                  ) : null}
                                  <TableCell
                                    style={{
                                      border: "2px solid lightgray",
                                      borderRight: 0,
                                      minWidth: 75,
                                    }}
                                    align="center"
                                  >
                                    UL&nbsp;
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "2px solid lightgray",
                                      borderRight: 0,
                                      minWidth: 75,
                                    }}
                                    align="center"
                                  >
                                    Area1&nbsp;
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "2px solid lightgray",
                                      borderRight: 0,
                                      minWidth: 75,
                                    }}
                                    align="center"
                                  >
                                    Area2&nbsp;
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "2px solid lightgray",
                                      borderRight: 0,
                                      width: "7%",
                                    }}
                                    align="center"
                                  >
                                    Da personalizzare&nbsp;
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "2px solid lightgray",
                                      minWidth: 175,
                                    }}
                                    align="center"
                                  >
                                    Data&nbsp;
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "2px solid lightgray",
                                      width: "1%",
                                    }}
                                    align="center"
                                  >
                                    Modifica Contenuto
                                  </TableCell>
                                  <TableCell style={{ border: 0 }}></TableCell>
                                </TableRow>
                              </TableHead>
                            ) : null}
                            <TableBody>
                              {this.props.item3.sottoparagrafi.map(
                                (item4, index4) => {
                                  if (index4 > 0) {
                                    return (
                                      <SottoparagrafoParti
                                        key={index4}
                                        item4={item4}
                                        /*   titolo={item4.titolo}
                                    base={item4.base}
                                    daEditare={item4.daEditare}
                                    data={item4.data}
                                    id={item4.id}
                                    isSelectedForPDF={item4.isSelectedForPDF}
                                    ul={item4.ul}
                                    sv={item4.sv}
                                    area1={item4.area1}
                                    area2={item4.area2}
                                    isOrganigramma={item4.hasOrganigramma}
                                    tipologia={item4.tipologia}
                                    */
                                        index={this.props.index}
                                        index2={this.props.index2}
                                        index3={this.props.index3}
                                        index4={index4}
                                        item3={this.props.item3}
                                        item2={this.props.item2}
                                        /* posizione={item4.posizione}
                                    numeroIndice={item4.numeroIndice}*/
                                        handleSottoparagrafoDelete={
                                          this.props.handleSottoparagrafoDelete
                                        }
                                        handleSottoparagrafoMovementUpward={
                                          this.props
                                            .handleSottoparagrafoMovementUpward
                                        }
                                        handleSottoparagrafoMovementDownward={
                                          this.props
                                            .handleSottoparagrafoMovementDownward
                                        }
                                        activateMovement={
                                          this.props.activateMovement
                                        }
                                        handleAggiuntaAlManualeSottoparagrafo={
                                          this.props
                                            .handleAggiuntaAlManualeSottoparagrafo
                                        }
                                        handleParagrafoRedirect={
                                          this.props.handleParagrafoRedirect
                                        }
                                        handleCambioBaseSottoparagrafo={
                                          this.props
                                            .handleCambioBaseSottoparagrafo
                                        }
                                        handleCambioULSottoparagrafo={
                                          this.props
                                            .handleCambioULSottoparagrafo
                                        }
                                        handleCambioSVSottoparagrafo={
                                          this.props
                                            .handleCambioSVSottoparagrafo
                                        }
                                        handleCambioArea1Sottoparagrafo={
                                          this.props
                                            .handleCambioArea1Sottoparagrafo
                                        }
                                        handleCambioArea2Sottoparagrafo={
                                          this.props
                                            .handleCambioArea2Sottoparagrafo
                                        }
                                        handleCambioDaEditareSottoparagrafo={
                                          this.props
                                            .handleCambioDaEditareSottoparagrafo
                                        }
                                        handleCambioIndiceSottoparagrafo={
                                          this.props
                                            .handleCambioIndiceSottoparagrafo
                                        }
                                        handleCambioDataSottoparagrafo={
                                          this.props
                                            .handleCambioDataSottoparagrafo
                                        }
                                        handleCambioTitoloSottoparagrafo={
                                          this.props
                                            .handleCambioTitoloSottoparagrafo
                                        }
                                        saveSottoparagrafoData={
                                          this.props.saveSottoparagrafoData
                                        }
                                        handleParagrafoClosing={
                                          this.handleParagrafoClosing
                                        }
                                        isModello={this.props.isModello}
                                      />
                                    );
                                  } else {
                                    return null;
                                  }
                                }
                              )}
                            </TableBody>
                          </Table>
                        </Box>
                      ) : null}
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Fragment>
    );
  }
}
export default ParagrafoParti;
