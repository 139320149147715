import React from "react";
import { Modal, TextField, Typography } from "@material-ui/core";
import FormGiornaliero from "../manualecomponents/dettaglio_procedura/FormGiornaliero";
import FormMensile from "../manualecomponents/dettaglio_procedura/FormMensile";
import FormSettimanale from "../manualecomponents/dettaglio_procedura/FormSettimanale";
import FormAnnuale from "../manualecomponents/dettaglio_procedura/FormAnnuale";
import ActionButton from "../../../components/ActionButton";
import FormAltraCadenza from "./FormAltraCadenza";

const styles = {
  modalcontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    padding: "10px",
    borderRadius: "6px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "auto",
  },
  miniModalContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    padding: "10px",
    borderRadius: "6px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "auto",
    height: "300px",
    display: "flex",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
};

export default class ModalSelezioneOrario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orariDisponibili: [
        "0:00",
        "1:00",
        "2:00",
        "3:00",
        "4:00",
        "5:00",
        "6:00",
        "7:00",
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
      colonneOrario: [],
      altraCadenza: this.props.controlloConformita.altraCadenza,
      selectedOrario: null,
      cadenze: this.props.cadenze,
    };
  }
  componentDidMount = () => {};
  onChange = () => {};
  onAddCadenza = (cadenza) => {
    var arrayOfCadenze = [...this.state.cadenze];
    arrayOfCadenze.push(cadenza);
    arrayOfCadenze.map((item) => {
      item["controlloConformitaId"] = this.props.controlloConformita.id;
    });
    this.setState({
      cadenze: arrayOfCadenze,
    });
    //this.props.setCadenzeToControllo(arrayOfCadenze);
  };
  onEliminaCadenzaButtonClicked = (selectedOrario) => {
    let arrayOfCadenze = [...this.state.cadenze];
    arrayOfCadenze.splice(selectedOrario, 1);
    this.setState({
      cadenze: arrayOfCadenze,
    });
    //this.props.setCadenzeToControllo(arrayOfCadenze);
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        {this.props.mode === "Normal" ? (
          <div style={styles.modalcontainer}>
            <div style={styles.modalsectioncontainer}>
              <Typography style={{ align: "center", fontSize: 30 }}>
                Orario del controllo
              </Typography>
              {this.props.cadenza === "GIORNALIERA" ? (
                <FormGiornaliero
                  controlloConformita={this.props.controlloConformita}
                  onChange={this.onChange}
                  onAddCadenza={this.onAddCadenza}
                  cadenze={this.state.cadenze}
                  onEliminaCadenzaButtonClicked={
                    this.onEliminaCadenzaButtonClicked
                  }
                />
              ) : null}
              {this.props.cadenza === "SETTIMANALE" ? (
                <FormSettimanale
                  controlloConformita={this.props.controlloConformita}
                  onChange={this.onChange}
                  onAddCadenza={this.onAddCadenza}
                  cadenze={this.state.cadenze}
                  onEliminaCadenzaButtonClicked={
                    this.onEliminaCadenzaButtonClicked
                  }
                />
              ) : null}
              {this.props.cadenza === "MENSILE" ? (
                <FormMensile
                  controlloConformita={this.props.controlloConformita}
                  onChange={this.onChange}
                  onAddCadenza={this.onAddCadenza}
                  cadenze={this.state.cadenze}
                  onEliminaCadenzaButtonClicked={
                    this.onEliminaCadenzaButtonClicked
                  }
                />
              ) : null}
              {this.props.cadenza === "ANNUALE" ? (
                <FormAnnuale
                  controlloConformita={this.props.controlloConformita}
                  onChange={this.onChange}
                  onAddCadenza={this.onAddCadenza}
                  cadenze={this.state.cadenze}
                  onEliminaCadenzaButtonClicked={
                    this.onEliminaCadenzaButtonClicked
                  }
                />
              ) : null}
              {this.props.cadenza !== "ANNUALE" &&
              this.props.cadenza !== "MENSILE" &&
              this.props.cadenza !== "SETTIMANALE" &&
              this.props.cadenza !== "GIORNALIERA" ? (
                <FormAltraCadenza
                  altraCadenza={this.props.cadenza}
                  onEliminaCadenzaButtonClicked={
                    this.onEliminaCadenzaButtonClicked
                  }
                  controlloConformita={this.props.controlloConformita}
                  onChange={this.onChange}
                  onAddCadenza={this.onAddCadenza}
                  cadenze={this.state.cadenze}
                />
              ) : null}
              <div>
                <ActionButton
                  label="Chiudi"
                  isRed
                  onClick={this.props.onClose}
                />
                <ActionButton
                  label="Salva"
                  onClick={() =>
                    this.props.setCadenzeToControllo(this.state.cadenze)
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <div style={styles.miniModalContainer}>
            <div style={styles.modalsectioncontainer}>
              <Typography style={{ align: "center", fontSize: 30 }}>
                Indicare altra cadenza
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                onChange={(event) => {
                  this.setState({ altraCadenza: event.target.value });
                }}
                value={this.state.altraCadenza}
              ></TextField>
            </div>
            <div style={{ flex: 1 }}></div>
            <div>
              <ActionButton label="Chiudi" isRed onClick={this.props.onClose} />
              <ActionButton
                label="Salva"
                onClick={() =>
                  this.props.addCadenza(
                    this.state.altraCadenza,
                    this.props.indexToUpdate
                  )
                }
              />
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
