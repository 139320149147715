import React, { Fragment } from "react";

import {
  Button,
  Checkbox,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import SpinnerComponent from "../../../components/SpinnerComponent";
import TwoActionsAlertDialog from "../../../components/TwoActionsAlertDialog";
import AlertDialog from "../../../components/AlertDialog";
import ErrorDialog from "../../../components/ErrorDialog";
import FormManuale from "./FormManuale";
import ModalAvvisoResponsabile from "./ModalAvvisoResponsabile";
import { clearToken } from "../../../utils/storage";
import {
  getRevisioneManuale,
  cancellaRevisione,
  updateRevisioneManuale,
  pubblicaManuale,
} from "../../../utils/api/revisioni_manuali_api";
import { getReparti } from "../../../utils/api/reparti";
import { getResponsabiliSicurezzaEsercizio } from "../../../utils/api/responsabili_sicurezza_api";
import { getEserciziCommerciali } from "../../../utils/api/esercizi_commerciali_api";
import theme from "../../../theme.js";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { getIndice } from "../../../utils/api/parti_api";
import Background from "../../../Sfondo.png";
import ModalAvvisoDaEditare from "./ModalAvvisoDaEditare";
import ModalRedirectToTabella from "./ModalRedirectToTabella";
import moment from "moment/moment.js";
import ModalAvvisoControlloAttrezzatura from "./ModalAvvisoControlloAttrezzatura.jsx";
import { getControlliSenzaAttrezzature } from "../../../utils/api/componenti_paragrafi_api.js";

const styles = {
  root: {
    textAlign: "center",
    color: "black",
    padding: "20px 10px 10px 10px",
    backgroundImage: `url("${Background}"),url("${Background}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "",
    backgroundAttachment: "fixed",
    backgroundPositionX: "140%, -40%",
  },
  paper: {
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
  },
  maincontainer: {
    padding: "0px 4px 10px 4px",
    maxWidth: "800px",
    minHeight: "480px",
  },
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "30px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  text: {
    width: "100%",
    padding: "10px 20px",
    textAlign: "center",
  },
};
export const Component = withRouter(({ history, location }) => {});

class DettaglioManualeSicurezza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: null,
      responsabileSicurezza: [],
      errorDialogVisible: false,
      errorDialogMessage: "",
      errorDialogTitle: null,
      loading: true,
      esercizi: [],
      controlliSenzaAttrezzature: [],
      saveButtonEnabled: false,
      saveMessageVisible: false,
      showPubblicatoMsg: false,
      disabledPubblicaButton:false,
      checkbox: true,
      alertVisible: false,
      twoActionsAlertVisible: false,
      openAlertReparti: false,
      openAlertDaEditare: false,
      showModalRedirect: false,
      showModalAvvisoControlloAttrezzatura: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.fetchRevisione();
  }

  componentDidUpdate = (_prevProps, prevState) => {
    if (prevState.record && this.state.record) {
      if (
        prevState.record.esercizioCommercialeId !==
        this.state.record.esercizioCommercialeId
      )
        this.fetchResponsabile();
    }
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  handleInvalidToken = () => {
    const errorMessage =
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    this.showErrorDialog(null, errorMessage);
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  showErrorDialog = (title, message) => {
    this.setState({
      errorDialogVisible: true,
      errorDialogTitle: title,
      errorDialogMessage: message,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  closeModalRedirect = () => {
    this.setState({
      showModalRedirect: false,
    });
  };

  closeModalAvvisoControlloAttrezzatura = () => {
    this.setState({
      showModalAvvisoControlloAttrezzatura: false,
    });
  };

  redirectToControlPanel = () => {
    this.props.history.push("/amministrazione/manuali");
  };

  fetchRevisione = () => {
    getRevisioneManuale(this.props.revisioneManualeId)
      .then((result) => {
        if (result.manuale.hidden) {
          this.setState({
            loading: false,
          });
        } else {
          if (result.manuale.hidden) {
            this.setState({
              loading: false,
              record: null,
            });
          } else {
            this.setState({
              record: result,
            });
            this.fetchEserciziCommerciali(result);
            this.fetchResponsabile();
            this.fetchControlliSenzaAttrezzature();
          }
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        else if(error.status === 409) {
          this.setState({
            loading: false,
          });
          this.showErrorDialog("Attenzione", "Pubblicazione in corso, attendere qualche secondo e poi aggiornare la pagina");
        }
        else {
          this.setState({
            loading: false,
          });
          this.showErrorDialog(null, error.message);
        }
      });
  };

  fetchEserciziCommerciali = (revisioneManuale) => {
    let consulenteId = null;
    if (!this.props.superconsulenteView && this.props.consulenteId !== null) {
      // If user has role 'consulente':
      consulenteId = this.props.consulenteId;
    } else {
      // Otherwise, if user is admin or superconsulente then esercizi are filtered only by group, if the revisione is not assigned to a consulente.
      // In that case get all esercizi that are associated to this consulente.
      consulenteId =
        revisioneManuale.consulenteId === null
          ? null
          : revisioneManuale.consulenteId;
    }
    getEserciziCommerciali(
      this.props.gruppoId,
      consulenteId,
      null,
      0,
      3000,
      "id"
    )
      .then((result) => {
        this.setState({
          esercizi: result,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.showErrorDialog(null, error.message);
        }
      });
  };

  fetchResponsabile = () => {
    getResponsabiliSicurezzaEsercizio(this.state.record.esercizioCommercialeId)
      .then((response) => this.setState({ responsabileSicurezza: response }))
      .catch((error) => {
        console.log("Errore nel recupero del responsabile", error);
        this.setState({
          responsabileSicurezza: [],
        });
      });
  };

  fetchControlliSenzaAttrezzature = () => {
    getControlliSenzaAttrezzature(this.state.record?.manuale.id)
      .then((response) =>
        this.setState({ controlliSenzaAttrezzature: response })
      )
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.showErrorDialog(null, error.message);
      });
  };

  deleteRevisioneManuale = () => {
    cancellaRevisione(this.state.record.id, true)
      .then((result) => {
        this.setState({
          alertVisible: true,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.showErrorDialog(null, error.message);
        }
      });
  };

  updateRevisione = () => {
    updateRevisioneManuale(this.state.record)
      .then((result) => {
        this.setState({
          loading: false,
          saveButtonEnabled: false,
          saveMessageVisible: true,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.showErrorDialog(null, error.message);
        }
      });
  };

  pubblicaRevisioneManuale = () => {
    this.setState({
      loading: true,
      openAlertReparti: false,
    });
    pubblicaManuale(this.state.record.manuale.id)
      .then((result) => {
        this.setState({
          showModalRedirect: true,
          showPubblicatoMsg: true,
          saveButtonEnabled: false,
          loading: false,
        });
        this.fetchRevisione();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } 
        if(error.message === 'ERR-003'){
          this.setState({
            loading: false,
          });
          this.showErrorDialog("Attenzione","Attenzione, è già in corso una pubblicazione di un manuale. Attendere la conclusione della suddetta pubblicazione e poi riprovare.");
        }
        else {
          this.setState({
            loading: false,
          });
          this.showErrorDialog(null, error.message);
        }
      });
  };

  handlePuntoVenditaSelection = (selectedPdV) => {
    this.setState({
      saveMessageVisible: false,
      saveButtonEnabled: true,
      // loading: true,
    });
    this.changeEsercizio(selectedPdV);
    // // Prima di impostare un nuovo punto vendita, mostra all'utente un alert
    // // per chiedere di rimuovere i responsabili associati ai reparti
    // getReparti(this.state.record.manuale.id, 0, 500, "id")
    //   .then((result) => {
    //     const hasAssignedResponsabili = result.some(
    //       (reparto) => reparto.responsabileSicurezzaId !== null
    //     );
    //     if (hasAssignedResponsabili) {
    //       // se ci sono responsabili assegnati, mostra l'alert
    //       const title = "ATTENZIONE";
    //       const errorMessage =
    //         "Prima di cambiare l'esercizio associato al manuale rimuovere tutti i responsabili dei punti vendita associati ai reparti nel paragrafo dedicato alla descrizione dei reparti.";
    //       this.showErrorDialog(title, errorMessage);
    //     } else {
    //       // se non ci sono responsabili assegnati, procedi con il cambio di esercizio
    //       this.changeEsercizio(selectedPdV);
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.status === 403) {
    //       this.handleInvalidToken();
    //     } else {
    //       this.showErrorDialog(
    //         null,
    //         "Si è verificato un errore. Riprovare più tardi."
    //       );
    //     }
    //   })
    //   .finally(() => {
    //     this.setState({ loading: false });
    //   });
  };

  changeEsercizio = (selectedPdV) => {
    // Trova il responsabile dell'esercizio selezionato, se esiste
    const esercizio = this.state.esercizi.find(
      (esercizio) => esercizio.id === selectedPdV.id
    );
    const responsabileId = esercizio ? esercizio.responsabileSicurezzaId : null;

    // Aggiorna lo stato con i nuovi valori
    this.setState((prevState) => ({
      record: {
        ...prevState.record,
        esercizioCommercialeId: selectedPdV.id,
        esercizioCommercialeNome: selectedPdV.ragioneSociale,
        responsabileSicurezzaId: responsabileId,
      },
    }));
  };

  onChangeDeliveryDate = (e) => {
    let recordWithDataDiConsegnaAggiornata = { ...this.state.record };
    recordWithDataDiConsegnaAggiornata.dataConsegna = moment(e).utc();
    this.setState({
      saveMessageVisible: false,
      record: recordWithDataDiConsegnaAggiornata,
      saveButtonEnabled: true,
    });
  };

  onChangeData = (event) => {
    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveMessageVisible: false,
        saveButtonEnabled: true,
      });
    }

    const name = event.target.name;
    const value = event.target.value;
    if (name === "esercizioCommercialeId") {
      // Before setting the new esercizio, show an alert to user
      // to ask to remove responsabili associated to reparti:
      this.setState({
        loading: true,
      });
      getReparti(this.state.record.manuale.id, 0, 500, "id")
        .then((result) => {
          const hasAssignedResponsabili = result.some(
            (reparto) => reparto.responsabileSicurezzaId !== null
          );
          if (hasAssignedResponsabili) {
            this.setState({
              loading: false,
            });
            const title = "ATTENZIONE";
            const errorMessage =
              "Prima di cambiare l'esercizio associato al manuale rimuovere tutti i responsabili dei punti vendita associati ai reparti nel paragrafo dedicato alla descrizione dei reparti.";
            this.showErrorDialog(title, errorMessage);
          } else {
            // Reparti are not associated to responsabili, it is possible to change esercizio:
            let esercizioid = value;
            let responsabileId = null;
            if (esercizioid !== null) {
              for (let i = 0; i < this.state.esercizi.length; i++) {
                if (this.state.esercizi[i].id === esercizioid) {
                  responsabileId =
                    this.state.esercizi[i].responsabileSicurezzaId;
                  break;
                }
              }
            }
            this.setState({
              loading: false,
              record: {
                ...this.state.record,
                esercizioCommercialeId: esercizioid,
                responsabileSicurezzaId: responsabileId,
              },
            });
          }
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.showErrorDialog(
              null,
              "Si è verificato un errore. Riprovare più tardi."
            );
          }
        });
    } else {
      let data = Object.assign({}, this.state.record);
      let manuale = Object.assign({}, data.manuale);
      if (name === "indiceRevisione" || name === "motivoRevisione") {
        data[name] = value;
      } else if (name === "titolo" || name === "regolamenti") {
        manuale[name] = value;
      }
      data.manuale = manuale;
      this.setState({
        record: data,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let data = this.state.record;
    if (data.esercizioCommercialeId === "") {
      data.esercizioCommercialeId = null;
    }
    this.setState({
      record: data,
      loading: true,
    });
    this.updateRevisione();
  };

  onPubblicaBtnClicked = () => {
    this.setState({
      disabledPubblicaButton: true,
      loading:true,
    },() => {
      let errorMessage = null;
      let title = null;
      let isDaEditare = false;
      getIndice(this.state.record.manuale.id)
        .then((result) => {
          result.parti.forEach((parte) => {
            parte.capitoli.forEach((capitolo) => {
              if (capitolo.daEditare && capitolo.lastUpdate === null) {
                isDaEditare = true;
              }
              capitolo.paragrafi.forEach((paragrafo) => {
                if (paragrafo.daEditare && paragrafo.lastUpdate === null) {
                  isDaEditare = true;
                }
                paragrafo.componenti.forEach((sottoparagrafo) => {
                  if (
                    sottoparagrafo.daEditare &&
                    sottoparagrafo.lastUpdate === null
                  ) {
                    isDaEditare = true;
                  }
                });
              });
            });
          });
          this.setState({
            disabledPubblicaButton: false,
            loading:false,
          })
          if (this.state.saveButtonEnabled) {
            title = "Avviso";
            errorMessage = "Salvare le modifiche prima di procedere.";
          } else if (this.state.record.esercizioCommercialeId === null) {
            errorMessage = "Associare un punto vendita al manuale.";
          } else if (
            this.state.record.motivoRevisione === null ||
            this.state.record.motivoRevisione.length === 0
          ) {
            errorMessage = "Inserire il motivo della revisione.";
          } else if (
            this.state.record.indiceRevisione === null ||
            this.state.record.indiceRevisione.length === 0
          ) {
            errorMessage = "Inserire il numero della revisione.";
          } else if (this.state.checkbox !== true) {
            errorMessage = "Accettare le condizioni d'uso";
          } else if (this.state.record.manuale.titolo.length === 0) {
            errorMessage = "Inserire il titolo del manuale.";
          } else if (this.state.record.manuale.regolamenti.length === 0) {
            errorMessage = "Inserire i regolamenti del manuale.";
          }
          if (errorMessage !== null) {
            this.showErrorDialog(title, errorMessage);
          } else {
            if (isDaEditare) {
              this.setState({
                openAlertDaEditare: true,
              });
            } else {
              this.checkResponsabileEsercizio();
            }
          }
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
              disabledPubblicaButton: false,
            });
            this.showErrorDialog(
              null,
              "Si è verificato un errore. Riprovare più tardi."
            );
          }
        });
    })
  };

  checkResponsabileEsercizio = () => {
    if (this.state.responsabileSicurezza.length === 0) {
      this.setState({
        openAlertReparti: true,
      });
    } else {
      this.checkControlliSenzaAttrezzature();
    }
  };

  checkControlliSenzaAttrezzature = () => {
    if (this.state.controlliSenzaAttrezzature.length > 0) {
      this.setState({ showModalAvvisoControlloAttrezzatura: true });
    } else {
      this.pubblicaRevisioneManuale();
    }
  };

  handleModalIndietroButtonClicked = () => {
    this.setState({
      openAlertReparti: false,
    });
  };

  handleModalDaEditareIndietroButtonClicked = () => {
    this.setState({
      openAlertDaEditare: false,
    });
  };

  onEliminaButtonClicked = () => {
    this.setState({
      twoActionsAlertVisible: true,
    });
  };

  closeTwoActionsAlert = () => {
    this.setState({
      twoActionsAlertVisible: false,
    });
  };

  handleCancellaManuale = () => {
    this.setState({
      loading: true,
      twoActionsAlertVisible: false,
    });
    this.deleteRevisioneManuale();
  };

  handleModalControlloAttrezzaturaConfirm = () => {
    this.setState(
      { showModalAvvisoControlloAttrezzatura: false },
      this.pubblicaRevisioneManuale
    );
  };

  closeAlertOnDeleteSuccessful = () => {
    this.setState({
      alertVisible: false,
      record: null,
      loading: false,
    });
  };

  handleCheck = () => {
    this.setState((prevState) => ({ checkbox: !prevState.checkbox }));
  };

  render() {
    const disableSubmit = !this.state.saveButtonEnabled;
    let disablePubblicaButton = true;
    let disableForm = true;
    if (this.state.record !== null) {
      disableForm = this.state.record.stato === "PUBBLICATO";
      disablePubblicaButton = this.state.record.stato === "PUBBLICATO";
    }
    return (
      <Fragment>
        <div
          style={{
            textAlign: "center",
            color: "black",
            padding: "20px 10px 10px 10px",
            backgroundImage:
              this.state.width > 1160
                ? `url("${Background}"),url("${Background}")`
                : `url(""),url("")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "",
            backgroundAttachment: "fixed",
            backgroundPositionX:
              this.state.width > 1799
                ? "140%, -40%"
                : this.state.width > 1599
                ? "160%, -60%"
                : this.state.width > 1399
                ? "180%, -80%"
                : this.state.width > 1299
                ? "200%, -100%"
                : "220%, -120%",
          }}
        >
          <Typography variant="h4" style={{ marginTop: 30, marginBottom: 40 }}>
            Dettagli manuale di sicurezza
          </Typography>
          <Container id="maincontainer" style={styles.maincontainer}>
            {this.state.loading ? (
              <SpinnerComponent size={24} />
            ) : this.state.record === null ? (
              <Typography
                variant="h6"
                style={{ paddingTop: "20px", paddingBottom: "20px" }}
              >
                Nessun record trovato
              </Typography>
            ) : (
              <Fragment>
                <FormManuale
                  record={this.state.record}
                  esercizi={this.state.esercizi}
                  responsabileSicurezza={this.state.responsabileSicurezza}
                  onChange={this.onChangeData}
                  onChangeDeliveryDate={this.onChangeDeliveryDate}
                  onSubmit={this.handleSubmit}
                  onEliminaButtonClicked={this.onEliminaButtonClicked}
                  disabledForm={disableForm}
                  disabledSubmitButton={disableSubmit}
                  saveSuccessMessageVisible={this.state.saveMessageVisible}
                  onPuntoVenditaSelected={this.handlePuntoVenditaSelection}
                />
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={styles.sectioncontainer}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ paddingTop: "6px" }}
                  >
                    <Grid container justify="center" alignItems="center">
                      <Typography variant="body1" style={styles.text}>
                        Cliccare sul pulsante PUBBLICA per pubblicare il
                        manuale.
                      </Typography>
                      <Typography variant="body1" style={styles.text}>
                        Dopo questa operazione non sarà più possibile
                        modificarne il contenuto.
                      </Typography>
                      {this.state.consulenteid !== null &&
                      this.state.consulenteid !== "null" ? (
                        <div>
                          <Checkbox
                            defaultChecked
                            onClick={this.handleCheck}
                            style={{
                              color: theme.palette.cndColors.yellow,
                              "&.MaterialUiChecked": {
                                color: theme.palette.cndColors.yellow,
                              },
                            }}
                            value={this.state.checkbox}
                          />
                          <a
                            href="https://bsfood.biorsaf.it/condizioni-generali-uso-BS-FOOD.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Dichiaro di aver letto e di accettare le Condizioni
                            d’Uso di BS-CONAD
                          </a>
                        </div>
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ paddingTop: "10px" }}
                    >
                      <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography
                            variant="body1"
                            style={{
                              color: theme.palette.primary.main,
                              visibility: this.state.showPubblicatoMsg
                                ? "inherit"
                                : "hidden",
                            }}
                          >
                            Manuale pubblicato con successo.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  style={{ paddingTop: "6px" }}
                >
                  <Button
                    type="button"
                    onClick={this.redirectToControlPanel}
                    variant="contained"
                    size="medium"
                    style={{
                      color: theme.palette.secondary.main,
                      margin: "10px",
                      borderRadius: "30px",
                      backgroundColor: theme.palette.cndColors.red,
                    }}
                  >
                    Indietro
                  </Button>
                  <Button
                    type="button"
                    onClick={this.onPubblicaBtnClicked}
                    variant="contained"
                    size="medium"
                    disabled={disablePubblicaButton || this.state.disabledPubblicaButton}
                    style={{
                      color: theme.palette.secondary.main,
                      margin: "10px",
                      borderRadius: "30px",
                      backgroundColor: disablePubblicaButton || this.state.disabledPubblicaButton
                        ? theme.palette.disabled.main
                        : theme.palette.cndColors.yellow,
                    }}
                  >
                    PUBBLICA
                  </Button>
                </Grid>
              </Fragment>
            )}
          </Container>
        </div>
        <ErrorDialog
          open={this.state.errorDialogVisible}
          title={this.state.errorDialogTitle}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        <TwoActionsAlertDialog
          visible={this.state.twoActionsAlertVisible}
          title="Cancellazione bozza"
          message="Cliccare su OK per confermare la richiesta di cancellazione oppure Indietro per annullare la richiesta."
          onIndietroClicked={this.closeTwoActionsAlert}
          onOkClicked={this.handleCancellaManuale}
        />
        <AlertDialog
          isOpen={this.state.alertVisible}
          title="Operazione completata"
          message="Manuale eliminato con successo."
          onButtonClicked={this.closeAlertOnDeleteSuccessful}
        />
        {this.state.openAlertReparti && (
          <ModalAvvisoResponsabile
            open={this.state.openAlertReparti}
            onIndietroSelected={this.handleModalIndietroButtonClicked}
          />
        )}
        {this.state.openAlertDaEditare && (
          <ModalAvvisoDaEditare
            open={this.state.openAlertDaEditare}
            onIndietroSelected={this.handleModalDaEditareIndietroButtonClicked}
            onOkSelected={this.checkResponsabileEsercizio}
          />
        )}
        {this.state.showModalRedirect && (
          <ModalRedirectToTabella
            open={this.state.showModalRedirect}
            onClose={this.closeModalRedirect}
            onOkSelected={this.redirectToControlPanel}
            onIndietroSelected={this.closeModalRedirect}
          />
        )}
        {this.state.showModalAvvisoControlloAttrezzatura && (
          <ModalAvvisoControlloAttrezzatura
            open={this.state.showModalAvvisoControlloAttrezzatura}
            onClose={this.closeModalAvvisoControlloAttrezzatura}
            controlliSenzaAttrezzature={this.state.controlliSenzaAttrezzature}
            onConfirm={this.handleModalControlloAttrezzaturaConfirm}
          />
        )}
      </Fragment>
    );
  }
}
export default withRouter(DettaglioManualeSicurezza);

DettaglioManualeSicurezza.propTypes = {
  revisioneManualeId: PropTypes.string,
  gruppoId: PropTypes.number,
  consulenteId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
