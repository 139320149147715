import { retrieveToken } from "../storage";
import { wrapFetch, ApiError, wrapFetchWithDetailedErrors } from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getRevisioniManuali = (
  id,
  hidden,
  gruppoId,
  consulenteId,
  page,
  size,
  sortby
) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/revisioni";
  let query =
    "?" +
    "page=" +
    page +
    "&size=" +
    size +
    "&personalizzato.equals=true" +
    "&sort=" +
    sortby +
    "&" +
    sortby +
    ".dir=ASC" +
    "&hidden.equals=" +
    hidden;
  if (id !== null) {
    query = query + "&id.equals=" + id;
  }
  if (consulenteId !== null) {
    query = query + "&consulenteId.equals=" + consulenteId;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query = query + "&gruppoId.equals=" + gruppoId;
  }
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getTotaleRevisioniManuali = (
  id,
  hidden,
  gruppoId,
  consulenteId,
  page,
  size,
  sortby
) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/revisioni/count";
  let query = "?" + "personalizzato.equals=true" + "&hidden.equals=" + hidden;
  if (id !== null) {
    query = query + "&id.equals=" + id;
  }
  if (consulenteId !== null) {
    query = query + "&consulenteId.equals=" + consulenteId;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query = query + "&gruppoId.equals=" + gruppoId;
  }
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const getRevisioneManuale = (revisioneId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/revisioni/" + revisioneId;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 404) {
      message = "Revisione manuale non trovata.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

export const updateRevisioneManuale = (revisione) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/revisioni";
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      message = "Errore salvataggio: i dati non sono corretti.";
    } else if (status === 404) {
      message = "Errore salvataggio: revisione manuale non trovata.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(revisione),
    }),
    errorFunction
  );
};

export const cancellaRevisione = (id, hidden) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/revisioni/cancella";
  const URL = BASE_URL + PATH;
  const requestBody = {
    id: id,
    hidden: hidden,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400 || status === 404) {
      // Error 404 if ID does not correspond to an existing record:
      message = "Errore salvataggio: i dati non sono corretti.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "PUT",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
      body: JSON.stringify(requestBody),
    }),
    errorFunction
  );
};
export const getStatoFile = (revisioneId, stato) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/storage?revisioneId=" + revisioneId + "&tipo=" + stato;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: "GET",
      headers: {
        Authorization: authtoken,
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};

// New API call for publishing a Manuale 'personalizzato'
export const pubblicaManuale = (manualeId) => {
  const token = retrieveToken();
  const authtoken = "Bearer ".concat(token);
  const PATH = "/api/manuali/personalizzati/" + manualeId + "/pubblicazione";
  const URL = BASE_URL + PATH;
  const errorFunction = (status, serverMessage) => {
    let message = null;
    if (status === 403) {
      message =
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    } else if (status === 400) {
      if (
        serverMessage ===
        "Richiesta non valida: La revisione del manuale risulta già in stato PUBBLICATO."
      ) {
        message = "Richiesta non valida: la revisione del manuale risulta già pubblicata";
      } else {
        message =
          "Pubblicazione fallita: assicurarsi che il punto vendita collegato al manuale da pubblicare abbia accettato i termini d'uso e/o la privacy policy del servizio BS-CONAD.";
      }
    } else if (status === 401) {
      message =
        "Il servizio è in attesa del pagamento del canone annuale per il'punto vendita selezionato.";
    } else if (status === 409) {
      message = "ERR-003";
    }

    return new ApiError(status, message);
  };
  return wrapFetchWithDetailedErrors(
    fetch(URL, {
      method: "POST",
      headers: {
        Authorization: authtoken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      credentials: "include",
    }),
    errorFunction
  );
};
