export const wrapFetch = (apiRequest, apiErrorFunc) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .then((response) => {
        const status = response.status;
        if (status === 200) {
          if (response.headers.get("Content-Type") === "application/json") {
            resolve(response.json());
          } else {
            resolve(response);
          }
        } else if (status === 201) {
          if (response.headers.get("Content-Type") === "application/json") {
            resolve(response.json());
          } else {
            resolve(response);
          }
        } else if (status === 204) {
          resolve(response);
        }
        reject(apiErrorFunc(status));
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
          reject(
            new ApiError(
              -1,
              "Servizio temporaneamente non disponibile. Riprovare più tardi."
            )
          );
        }
        reject(
          new ApiError(0, "Si è verificato un errore. Operazione non riuscita.")
        );
      });
  });
};

export const wrapFetchWithDetailedErrors = (apiRequest, apiErrorFunc) => {
  return new Promise((resolve, reject) => {
    apiRequest
      .then((response) => {
        const status = response.status;
        if (status >= 200 && status < 300) {
          if (
            response.headers.get("Content-Type")?.includes("application/json")
          ) {
            return response.json().then((data) => resolve(data));
          } else {
            return resolve(response);
          }
        }

        if (status >= 400 && status < 500) {
          return response.text().then((errorMessage) => {
            try {
              const parsedError = JSON.parse(errorMessage);
              const detailedMessage =
                parsedError.message +
                (parsedError.details
                  ? `: ${parsedError.details.join(" ")}`
                  : "");
              reject(apiErrorFunc(status, detailedMessage));
            } catch (e) {
              reject(apiErrorFunc(status, errorMessage));
            }
          });
        }
        reject(apiErrorFunc(status));
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
          reject(
            new ApiError(
              -1,
              "Servizio temporaneamente non disponibile. Riprovare più tardi."
            )
          );
        } else {
          reject(
            new ApiError(
              0,
              "Si è verificato un errore. Operazione non riuscita."
            )
          );
        }
      });
  });
};

export class ApiError extends Error {
  constructor(status, message) {
    super(
      message ? message : "Si è verificato un errore. Operazione non riuscita"
    );
    this.status = status;
  }
}
